module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://notakaos.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KFFNBQG","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"adr":"/home/runner/work/notakaos-com-site/notakaos-com-site/gatsby/src/components/default-site-layout.tsx","blog":"/home/runner/work/notakaos-com-site/notakaos-com-site/gatsby/src/components/default-site-layout.tsx","default":"/home/runner/work/notakaos-com-site/notakaos-com-site/gatsby/src/components/default-site-layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-code-titles","options":{}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Dark+ (default dark)"}},{"resolve":"gatsby-remark-autolink-headers","options":{"enableCustomId":true,"isIconAfterHeader":true,"removeAccents":true,"elements":["h2","h3"]}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/notakaos-com-site/notakaos-com-site/gatsby"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
