// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-default-mdx-template-tsx": () => import("./../../../src/components/default-mdx-template.tsx" /* webpackChunkName: "component---src-components-default-mdx-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adr-index-tsx": () => import("./../../../src/pages/adr/index.tsx" /* webpackChunkName: "component---src-pages-adr-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

