import React, { createContext, useState, useEffect } from 'react'
export const isClient = typeof window !== 'undefined'
export const FULL_WIDTH_STATE_KEY = 'fullWidthState'
export const FULL_WIDTH_ENABLED = '1'
export const FULL_WIDTH_DISABLED = '0'

const defaultParams = {
  fullWidthEnabled: false,
  setFullWidthEnabled: (_newValue: boolean) => {
    console.log('setFullWidthEnabled')
  },
  toggleFullWidthEnabled: () => {
    console.log('toggleFullWidthEnabled')
  },
}

export const FullWidthEnabledContext = createContext(defaultParams)

const Provider = (props: any) => {
  const [fullWidthEnabled, setFullWidthEnabled] = useState(false)

  useEffect(() => {
    if (isClient) {
      const savedValue = localStorage.getItem(FULL_WIDTH_STATE_KEY)
      setFullWidthEnabled(savedValue === FULL_WIDTH_ENABLED)
    }
  })

  const toggleFullWidthEnabled = () => {
    const newValue = !fullWidthEnabled
    setFullWidthEnabled(newValue)
    if (isClient) {
      localStorage.setItem(FULL_WIDTH_STATE_KEY, newValue ? FULL_WIDTH_ENABLED : FULL_WIDTH_DISABLED)
    }
  }

  const setFullWidthEnabledCustom = (newValue: boolean) => {
    setFullWidthEnabled(newValue)
    if (isClient) {
      localStorage.setItem(FULL_WIDTH_STATE_KEY, newValue ? FULL_WIDTH_ENABLED : FULL_WIDTH_DISABLED)
    }
  }

  return (
    <FullWidthEnabledContext.Provider
      value={{
        fullWidthEnabled,
        setFullWidthEnabled: setFullWidthEnabledCustom,
        toggleFullWidthEnabled,
      }}
    >
      {props.children}
    </FullWidthEnabledContext.Provider>
  )
}

export const FullWidthEnabledProvider = ({ element }: { element: any }) => <Provider>{element}</Provider>
